export const environment = {
  production: false,
  apiUrl: 'https://api-documents-be-test.ez-court.net/api',
  userRegisterUrl: './homepage/index.html#/register',
  userApiUrl: 'https://api-my-homepage-be-test.ez-court.net/api',
  authServiceUrl: 'https://api-bankid-gw-test.ez-court.net/service',
  domain: 'ez-court.net',

  logoUrl: 'https://landing-page-test.ez-court.net',
}
